import React from 'react';

import {Container, Row, Col } from "react-bootstrap";

import './Footer.css';

function Footer() {
  return (
    <div className="footer-wrapper">
        <Container>
            <Row className="py-5 d-flex justify-content-center">
                <Col sm={2}>
                    <h6 className="bold-font left-side text-center pb-3"> Acerca de Nosotros </h6>
                    <ul>
                        <li className="my-2">
                            <a className="decr-none" href="/acerca-de-nosotros"> Creencia </a>
                        </li>
                        <li className="my-2">
                            <a className="decr-none" href="/acerca-de-nosotros"> Nuestra Historia </a>
                        </li>
                    </ul>
                </Col>
                <Col sm={2}>
                    <h6 className="bold-font left-side text-center pb-3"> Ministerios </h6>
                    <ul>
                        <li className="my-2">
                            <a className="decr-none" href="/discipulados"> Discipulados </a>
                        </li>
                        <li className="my-2">
                            <a className="decr-none" href="/grupos-de-vida/"> Grupos de Vida </a>
                        </li>
                        <li className="my-2">
                            <a className="decr-none" href="/mfv-youth/"> MFV Youth </a>
                        </li>
                    </ul>
                </Col>
                <Col sm={2}>
                    <h6 className="bold-font left-side contact-container text-center pb-3"> Recursos </h6>
                    <ul>
                        <li className="my-2">
                            <a className="decr-none" href="/discipulados"> Discipulados </a>
                        </li>
                        <li className="my-2">
                            <a className="decr-none" href="/media"> Sermones </a>
                        </li>
                    </ul>
                </Col>
                <Col sm={3}>
                    <h6 className="bold-font left-side text-center pb-3"> Contáctenos </h6>
                    <ul>
                        <li className="my-2">
                            134 N Kenwood St. 4th floor <br/>
                            Glendale, CA 91208
                        </li>
                        <li className="my-2">
                            info@ministeriosfdv.com
                        </li>
                        <li className="my-2">
                            (818) 791-7974
                        </li>
                    </ul>
                </Col>
                <Col sm={3}>
                    <h6 className="bold-font left-side text-center pb-3"> Nuestros Horarios </h6>
                    <ul>
                        <li className="my-2">
                            <strong>Miércoles</strong> <br/>
                            7:30pm – Estudio Bíblico
                        </li>
                        <li className="my-2">
                            <strong> Viernes </strong> <br/>
                            7:30pm – Youth Group
                        </li>
                        <li className="my-2">
                            <strong> Domingos </strong> <br/>
                            10am – Servicio De Adoración
                        </li>
                    </ul>
                </Col>
            </Row>
            <div className="copyrights px-3 py-4">
                Source of Life Ministries © All rights reserved. Design by GSXLAB
            </div>
        </Container>
    </div>
  );
}

export default Footer;
