import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

// Components
import MainNav from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

require('dotenv').config();

function App() {

  const navLinks = [
    {
      id: 1,
      name: "Acerca de Nosotros",
      path: "/acerca-de-nosotros"
    },
    {
      id: 2,
      name: "Ministries",
      path: [
        {
          id: 1,
          name: "Discipulados",
          path: "/discipulados"
        },
        {
          id: 2,
          name: "Grupos de Vida",
          path: "/Grupos-de-Vida"
        },
        {
          id: 3,
          name: "MFV Youth",
          path: "/mfv-youth"
        }
      ]
    },
    {
      id: 3,
      name: "Sermons",
      path: "/sermons"
    },
  ]

  return (
    <Router>
      <div className="App">
        <MainNav links={navLinks}/>
          Sorry we're currently on maintenance please visit us back soon.
        <Footer/>
      </div>
    </Router>
  );
}

export default App;